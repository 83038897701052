<template>
  <div class="main">
    <div class="older-age">
      <div class="outer-padding box-contain">
        <div class="age-item">
          <div>{{ ageRange1 || 0 }}</div>
          <div>60-80岁</div>
        </div>
        <div class="age-item">
          <div>{{ ageRange2 || 0 }}</div>
          <div>80-90岁</div>
        </div>
        <div class="age-item">
          <div>{{ ageRange3 || 0 }}</div>
          <div>90-100岁</div>
        </div>
        <div class="age-item">
          <div>{{ ageRange4 || 0 }}</div>
          <div>100岁以上</div>
        </div>
      </div>
    </div>
    <div class="older-administration">
      <div id="chart"></div>
    </div>
    <div class="older-health">
      <div class="outer-padding health-type">
        <div>
          <span>健康人数</span>
          <span style="color: #32cf57">{{ healthNum || 0 }} </span>
        </div>
        <div>
          <span>亚健康人数</span> <span>{{ subHealthNum || 0 }} </span>
        </div>
        <div>
          <span>疾病人数</span>
          <span style="color: #ff3c1d">{{ diseaseNum || 0 }} </span>
        </div>
      </div>
    </div>
    <div class="older-ability">
      <div class="outer-padding box-contain">
        <div class="age-item">
          <div>{{ healthMessage.capacity.selfCare.value || 0 }}</div>
          <div>自理</div>
        </div>
        <div class="age-item">
          <div>{{ healthMessage.capacity.mobility.value || 0 }}</div>
          <div>介助</div>
        </div>
        <div class="age-item">
          <div>{{ healthMessage.capacity.nurse.value || 0 }}</div>
          <div>介护</div>
        </div>
        <div class="age-item">
          <div>{{ healthMessage.capacity.specialNurse.value || 0 }}</div>
          <div>特护</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import { getAreaOlderMessage, getAreaHealth } from '@/api/secondaryPage'
export default {
  props: ['areaRatinAge'],
  data() {
    return {
      healthMessage: {
        health: [
          {
            key: '亚健康',
            value: 1,
          },
        ], // 健康
        capacity: {
          selfCare: {
            key: '自理',
            value: 0,
          },
          mobility: {
            key: '介助',
            value: 0,
          },
          nurse: {
            key: '介护',
            value: 0,
          },
          specialNurse: {
            key: '特护',
            value: 0,
          },
        }, // 评估能力
      }, //区域内-老龄人能力评估、健康类型
    }
  },
  computed: {
    //60-80岁
    ageRange1() {
      //接口返回数组是60-70、70-80分组
      if (!this.areaRatinAge.ageGroup.length) return
      let tempTotal1 =
        this.areaRatinAge.ageGroup.find(
          (item) => item.min == 60 && item.max == 70
        ) &&
        this.areaRatinAge.ageGroup.find(
          (item) => item.min == 60 && item.max == 70
        ).total
      let tempTotal2 =
        this.areaRatinAge.ageGroup.find(
          (item) => item.min == 70 && item.max == 80
        ) &&
        this.areaRatinAge.ageGroup.find(
          (item) => item.min == 70 && item.max == 80
        ).total

      return tempTotal1 + tempTotal2
    },
    //80-90岁
    ageRange2() {
      let number =
        this.areaRatinAge.ageGroup.find(
          (item) => item.min == 80 && item.max == 90
        ) &&
        this.areaRatinAge.ageGroup.find(
          (item) => item.min == 80 && item.max == 90
        ).total
      return number || 0
    },
    //90-100岁
    ageRange3() {
      let number =
        this.areaRatinAge.ageGroup.find(
          (item) => item.min == 90 && item.max == 100
        ) &&
        this.areaRatinAge.ageGroup.find(
          (item) => item.min == 90 && item.max == 100
        ).total
      return number || 0
    },
    //100岁以上
    ageRange4() {
      let number =
        this.areaRatinAge.ageGroup.find((item) => item.min == 100) &&
        this.areaRatinAge.ageGroup.find((item) => item.min == 100).total
      return number || 0
    },
    //健康人数
    healthNum() {
      let tempNum =
        this.healthMessage.health.find((item) => item.key == '健康人群') &&
        this.healthMessage.health.find((item) => item.key == '健康人群').value
      return tempNum
    },
    //亚健康
    subHealthNum() {
      let tempNum =
        this.healthMessage.health.find((item) => item.key == '亚健康人群') &&
        this.healthMessage.health.find((item) => item.key == '亚健康人群').value
      return tempNum
    },
    //疾病
    diseaseNum() {
      let tempNum =
        this.healthMessage.health.find((item) => item.key == '疾病人群') &&
        this.healthMessage.health.find((item) => item.key == '疾病人群').value
      return tempNum
    },
  },
  mounted() {
    this.getAreaOlderMessage()
    this.getAreaHealth()
  },
  methods: {
    getAreaOlderMessage() {
      const { code = '510104' } = this.$route.query
      getAreaOlderMessage(code).then((res) => {
        if (res.data.code == 0) {
          var data
          if (res.data.data.length === 0 || res.data.data == null) {
            data = [
              {
                name: '暂无数据',
                value: 0,
              },
            ]
          } else {
            data = res.data.data.map((item) => {
              const copy = item
              return { name: copy.streetName, value: copy.num }
            })
          }
          this.$nextTick(() => {
            var chart = echarts.init(document.getElementById('chart'))
            let option = {
              color: [
                '#ff1b55',
                '#fffe02',
                '#61feff',
                '#6861ff',
                '#ff9f1b',
                '#30f279',
                '#ff35f0',
                '#359dff',
              ],
              tooltip: {
                trigger: 'item',
                formatter: '{b} : {d}%',
              },
              series: [
                {
                  label: {
                    normal: {
                      show: true,
                      fontSize: 8,
                    },
                  },
                  labelLine: {
                    normal: {
                      show: true,
                    },
                  },
                  name: 'Nightingale Chart',
                  type: 'pie',
                  radius: [20, 60],
                  center: ['50%', '50%'],
                  roseType: 'area',

                  // label: {
                  //   formatter: '{b}\n {d}%',
                  //   position: 'outer',
                  //   alignTo: 'edge',
                  //   margin: 10,
                  // },
                  itemStyle: {
                    borderRadius: 8,
                  },
                  data,
                },
              ],
            }
            chart.setOption(option)
            window.addEventListener('resize', function () {
              chart.resize()
            })
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getAreaHealth() {
      const { areaCode = '510104' } = this.$route.query
      getAreaHealth(areaCode).then((res) => {
        if (res.data.code === 0) {
          this.healthMessage = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
  },
}
</script>

<style scoped>
.main {
  margin-top: 0.42rem;
  display: grid;
  grid-template-columns: repeat(2, 12rem);
  grid-template-rows: repeat(2, 8.83rem);
  gap: 0.42rem;
}
.older-age,
.older-ability {
  background: url('../../../../image/twoThreeImgs/left/olderAgeBg.png');
  height: 8.83rem;
  width: 12rem;
}
.older-ability {
  background: url('../../../../image/twoThreeImgs/left/abilityBg.png');
}
.outer-padding {
  margin-top: 1.33rem;
  margin-left: 0.42rem;
  margin-right: 0.42rem;
}
.box-contain {
  display: grid;
  grid-template-columns: repeat(2, 5.42rem);
  grid-template-rows: repeat(2, 3.33rem);
  gap: 0.33rem;
}

.age-item {
  width: 5.42rem;
  height: 3.33rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
}
.age-item :first-child {
  width: fit-content;
  margin: 0 auto;
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #b4d3fe;
  line-height: 1.56rem;
  margin-top: 0.46rem;
  margin-bottom: 0.33rem;
}
.age-item :last-child {
  width: fit-content;
  margin: 0 auto;
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.67rem;
}
.older-administration {
  background: url('../../../../image/twoThreeImgs/left/administrationBg.png');
  height: 8.83rem;
  width: 12rem;
}
#chart {
  width: 11.12rem;
  height: 7rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  margin: 1.33rem 0.42rem 0.5rem;
}
.older-health {
  background: url('../../../../image/twoThreeImgs/left/healthBg.png');
  height: 8.83rem;
  width: 12rem;
}
.health-type {
  width: 11.12rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.health-type div {
  padding: 0 0.5rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  height: 2.125rem;
  width: calc(100% - 1rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.health-type div :first-child {
  font-size: 0.58rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #69b1ff;
  line-height: 0.83rem;
}
.health-type div :last-child {
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
  line-height: 1.45rem;
}
</style>
